export function addQueryParam(key, value) {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set(key, value);
  window.history.replaceState(null, "", currentUrl);
}

export function removeQueryParam(key) {
  const currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete(key);
  window.history.replaceState(null, "", currentUrl);
}

export function getQueryParam(key) {
  const currentUrl = new URL(window.location.href);
  return currentUrl.searchParams.get(key);
}
