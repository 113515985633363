import { ReactFlowProvider } from "reactflow";

import { TreeProvider } from "~/application/pages/tree/components/tree-context";
import Show from "~/application/pages/tree/show";

const Tree = () => (
  <TreeProvider>
    <ReactFlowProvider>
      <Show />
    </ReactFlowProvider>
  </TreeProvider>
);

export default Tree;
