import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useReactFlow } from "reactflow";
import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Icon,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import ConfirmationModal from "~/application/pages/tree/components/confirmation-modal";
import SolutionsList from "~/application/pages/tree/components/solutions-list";
import { Solution } from "~/application/pages/tree/components/solutions-preview";
import {
  TREE_ACTIONS,
  useTreeContext,
} from "~/application/pages/tree/components/tree-context";
import AutoResizeTextArea from "~/application/shared/components/auto-resize-text-area";
import IdeaActiveIcon from "~/application/shared/icons/idea-active-icon";
import { post as postHelper } from "~/application/utils/fetch";
import { UploadAdapter } from "~/application/utils/upload-adapter";
import { useDestroy, usePatch, usePost } from "~/application/utils/use-fetch";
import ProblemsAPI from "~/routes/api/problems";
import SolutionsAPI from "~/routes/api/solutions";

import Insight from "./insight";

import "~/application/style/ckeditor-reset.css";

const Section = chakra(Box, {
  baseStyle: {
    p: "32px 40px",
  },
});

const PanelButton = chakra(Button, {
  baseStyle: {
    textStyle: "bodyS",
    p: "8px 10px",
    border: "1px",
    borderRadius: "2px",
    height: "fit-content",
  },
});

const Hint = chakra(Text, {
  baseStyle: {
    borderRadius: "2px",
    backgroundColor: "yellow.50",
    borderWidth: "1px",
    borderColor: "red.50",
    p: "12px",
    textStyle: "bodyS",
    color: "gray.400",
  },
});

const metricReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_LABEL": {
      return {
        ...state,
        label: action.label,
      };
    }
    case "UPDATE_UNIT": {
      return {
        ...state,
        unit: action.unit,
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
};

const SidePanel = () => {
  const {
    state: {
      openProblem: {
        id,
        title: initialTitle,
        context: initialContext,
        problemType: initialProblemType,
        isNewProblem = false,
        solutionStats: solutions,
        isRoot,
        directChildren,
        metrics: initialMetrics,
        insights: initialInsights,
        insightsCount,
      },
      savedViewport,
      rootMetric,
    },
    treeId,
    dispatch,
  } = useTreeContext();

  const { getViewport, setViewport, fitView } = useReactFlow();
  const [post] = usePost();
  const [patchUpdate] = usePatch();
  const [destroy, loadingDestroy] = useDestroy();

  const [title, setTitle] = useState(initialTitle ?? "");
  const [context, setContext] = useState(initialContext ?? "");
  const [problemType, setProblemType] = useState(initialProblemType ?? "");
  const [insights, setInsights] = useState([]);
  // used for rootMetric
  const [metricState, metricDispatch] = useReducer(
    metricReducer,
    rootMetric ?? { label: "", unit: "percent" }
  );
  // children metric
  const [metric, setMetric] = useState(
    initialMetrics && (initialMetrics[0]?.value ?? undefined)
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const titleInputRef = useRef(null);

  useEffect(() => {
    setInsights(JSON.parse(initialInsights || "[]"));
  }, [initialInsights]);

  useEffect(() => {
    if (titleInputRef.current && isNewProblem) {
      titleInputRef.current.focus();
    }
  }, [isNewProblem]);

  // these three useEffects need to be here if a user wants to edit other problem node, while editing another one
  useEffect(() => {
    setTitle(initialTitle ?? "");
  }, [initialTitle, setTitle]);

  useEffect(() => {
    setContext(initialContext ?? "");
  }, [initialContext, setContext]);

  useEffect(() => {
    setMetric(initialMetrics && (initialMetrics[0]?.value ?? undefined));
  }, [initialMetrics, setMetric]);

  useEffect(() => {
    setProblemType(initialProblemType ?? "");
  }, [initialProblemType, setProblemType]);

  const hasUnsavedChanges = useMemo(
    () =>
      title !== initialTitle ||
      context !== initialContext ||
      problemType !== initialProblemType ||
      metricState.label !== rootMetric?.label ||
      metricState.unit !== rootMetric?.unit ||
      metricState.unit !== rootMetric?.unit ||
      metric !== (initialMetrics && initialMetrics[0]?.value),
    [
      title,
      initialTitle,
      context,
      initialContext,
      metricState,
      rootMetric,
      metric,
      initialMetrics,
      problemType,
      initialProblemType,
    ]
  );

  const isLeafNode = useMemo(() => !directChildren?.length, [directChildren]);

  useEffect(() => {
    // save viewport so it can be restored on close
    dispatch({
      type: TREE_ACTIONS.saveViewport,
      savedViewport: getViewport(),
    });

    // fit viewport so the opened node is visible
    setTimeout(
      () =>
        fitView({
          nodes: [{ id }],
          duration: 1_000,
          padding: 2,
        }),
      100
    );
  }, [id, dispatch, getViewport, fitView]);

  // on panel close, restore savedViewport if non null
  useEffect(() => {
    return () => {
      if (!savedViewport) return;

      setViewport(savedViewport, { duration: 1_000 });
      dispatch({ type: TREE_ACTIONS.saveViewport, viewport: null });
    };
  }, [savedViewport, setViewport, dispatch]);

  const handleTitleChange = useCallback(
    (event) => setTitle(event.target.value),
    [setTitle]
  );

  const handleSubmit = useCallback(async () => {
    if (!title) return;

    const { node } = await post(ProblemsAPI.create.path({ treeId }), {
      problem: {
        parentId: id,
        title,
        context,
        problemType,
      },
    });

    dispatch({ type: TREE_ACTIONS.addProblem, node });
    dispatch({ type: TREE_ACTIONS.openProblem, problem: node });
  }, [treeId, dispatch, id, title, context, post, problemType]);

  const deleteProblem = useCallback(async () => {
    if (!isNewProblem) {
      const { ids } = await destroy(ProblemsAPI.destroy.path({ id }));

      await dispatch({ type: TREE_ACTIONS.deleteProblem, ids });
    }

    dispatch({ type: TREE_ACTIONS.closeProblem });
  }, [dispatch, destroy, id, isNewProblem]);

  const handleDelete = useCallback(() => {
    if (isRoot) return;

    setIsDeleteModalOpen(true);
  }, [isRoot, setIsDeleteModalOpen]);

  const handleUpdate = useCallback(async () => {
    if (!title) return;

    const hasRootMetric = isRoot && metricState.label && metricState.unit;
    const { node } = await patchUpdate(ProblemsAPI.update.path({ id }), {
      problem: {
        title,
        context,
        problemType,
        insights: JSON.stringify(insights),
        ...(isRoot
          ? hasRootMetric && { metrics: [metricState] }
          : metric && {
              metrics: [{ value: metric, metric_definition_id: rootMetric.id }],
            }),
      },
    });

    await dispatch({ type: TREE_ACTIONS.updateProblem, node });

    if (hasRootMetric) {
      dispatch({ type: TREE_ACTIONS.setRootMetric, ...node.metrics[0] });
    }
  }, [
    dispatch,
    id,
    title,
    context,
    patchUpdate,
    isRoot,
    metricState,
    metric,
    rootMetric,
    problemType,
    insights,
  ]);

  const handleAddIdea = useCallback(async () => {
    const { node } = await post(SolutionsAPI.create.path({ treeId }), {
      solution: {
        parentId: id,
        title: "",
        state: "idea",
      },
    });

    dispatch({ type: TREE_ACTIONS.addSolution, node });
  }, [id, post, treeId, dispatch]);

  const handleAddInsight = useCallback(async () => {
    if (!title) return;
    setInsights((prev) => [
      ...prev,
      {
        id: Date.now() * Math.random(),
        label: "",
        user: "",
      },
    ]);

    const hasRootMetric = isRoot && metricState.label && metricState.unit;
    const { node } = await patchUpdate(ProblemsAPI.update.path({ id }), {
      problem: {
        title,
        context,
        problemType,
        insights: JSON.stringify([...insights, { label: "", user: "" }]),
        ...(isRoot
          ? hasRootMetric && { metrics: [metricState] }
          : metric && {
              metrics: [{ value: metric, metric_definition_id: rootMetric.id }],
            }),
      },
    });

    await dispatch({ type: TREE_ACTIONS.updateProblem, node });

    if (hasRootMetric) {
      dispatch({ type: TREE_ACTIONS.setRootMetric, ...node.metrics[0] });
    }
  }, [
    dispatch,
    id,
    title,
    context,
    patchUpdate,
    isRoot,
    metricState,
    metric,
    rootMetric,
    problemType,
    insights,
  ]);

  const handleUpdateInsight = async (insightId, insightLabel, insightUser) => {
    if (!insightId || !insightLabel || !insightUser) return;
    const updatedInsights = insights.map((insight) =>
      insight.id === insightId
        ? { id: insightId, label: insightLabel, user: insightUser }
        : insight
    );

    setInsights(updatedInsights);

    const hasRootMetric = isRoot && metricState.label && metricState.unit;
    const { node } = await patchUpdate(ProblemsAPI.update.path({ id }), {
      problem: {
        title,
        context,
        problemType,
        insights: JSON.stringify(updatedInsights),
        ...(isRoot
          ? hasRootMetric && { metrics: [metricState] }
          : metric && {
              metrics: [{ value: metric, metric_definition_id: rootMetric.id }],
            }),
      },
    });

    await dispatch({ type: TREE_ACTIONS.updateProblem, node });

    if (hasRootMetric) {
      dispatch({ type: TREE_ACTIONS.setRootMetric, ...node.metrics[0] });
    }
  };

  const handleRemoveInsight = async (insightId) => {
    const updatedInsights = insights.filter(
      (insight) => insight.id !== insightId
    );

    setInsights(updatedInsights);

    const hasRootMetric = isRoot && metricState.label && metricState.unit;
    const { node } = await patchUpdate(ProblemsAPI.update.path({ id }), {
      problem: {
        title,
        context,
        problemType,
        insights: JSON.stringify(updatedInsights),
        ...(isRoot
          ? hasRootMetric && { metrics: [metricState] }
          : metric && {
              metrics: [{ value: metric, metric_definition_id: rootMetric.id }],
            }),
      },
    });

    await dispatch({ type: TREE_ACTIONS.updateProblem, node });

    if (hasRootMetric) {
      dispatch({ type: TREE_ACTIONS.setRootMetric, ...node.metrics[0] });
    }
  };

  const handleImageUpload = useCallback(
    (editor) => async (file) => {
      try {
        const formData = new FormData();
        formData.append("file", file);

        const { fileUrl } = await postHelper("/api/images", formData, true);

        editor.execute("insertImage", { source: fileUrl });

        return { default: fileUrl };
      } catch (error) {
        // TODO: handle file upload error
        return { default: "" };
      }
    },
    []
  );

  return (
    <>
      <Box
        w="100%"
        h="100%"
        borderLeft="1px"
        borderLeftColor="gray.200"
        display="flex"
        flexDir="column"
        overflow="auto"
      >
        <Section>
          <AutoResizeTextArea
            placeholder="Enter title"
            textStyle="headingL"
            border={
              title === "" ? "1px solid #DD524C" : "1px solid rgba(0, 0, 0, 0)"
            }
            borderRadius="2px"
            size="xl"
            color="gray.900"
            focusBorderColor={title === "" ? "#DD524C" : "rgba(0, 0, 0, 0)"}
            value={title}
            ref={titleInputRef}
            onChange={handleTitleChange}
            onBlur={() =>
              hasUnsavedChanges &&
              (isNewProblem ? handleSubmit() : handleUpdate())
            }
            _hover={{
              borderColor: title === "" ? "#DD524C" : "rgba(0, 0, 0, 0)",
            }}
          />
        </Section>
        <Divider />
        <Section flex={1} mb="76px">
          <Flex flexDir="column" gap="16px" mb="32px">
            {isRoot && (
              <Flex gap="12px">
                <Input
                  placeholder="Enter metric label"
                  fontSize="sm"
                  size="xs"
                  borderRadius="2px"
                  borderColor="rgba(0, 0, 0, 0)"
                  focusBorderColor="gray.200"
                  color="gray.500"
                  _focus={{ backgroundColor: "gray.50" }}
                  value={metricState.label}
                  onChange={(event) =>
                    metricDispatch({
                      type: "UPDATE_LABEL",
                      label: event.target.value,
                    })
                  }
                  onBlur={() =>
                    hasUnsavedChanges &&
                    (isNewProblem ? handleSubmit() : handleUpdate())
                  }
                />
                <Select
                  placeholder="Select unit"
                  variant="flushed"
                  fontSize="sm"
                  size="xs"
                  borderRadius="2px"
                  borderColor="rgba(0, 0, 0, 0)"
                  focusBorderColor="rgba(0, 0, 0, 0)"
                  color="gray.500"
                  w="30%"
                  value={metricState.unit}
                  onChange={(event) =>
                    metricDispatch({
                      type: "UPDATE_UNIT",
                      unit: event.target.value,
                    })
                  }
                  onBlur={() =>
                    hasUnsavedChanges &&
                    (isNewProblem ? handleSubmit() : handleUpdate())
                  }
                >
                  <option value="percent">Percent</option>
                  <option value="number">Number</option>
                </Select>
              </Flex>
            )}
            <CKEditor
              editor={InlineEditor}
              data={context}
              onChange={(event, editor) => setContext(editor.getData())}
              onBlur={() =>
                hasUnsavedChanges &&
                (isNewProblem ? handleSubmit() : handleUpdate())
              }
              config={{
                image: {
                  upload: {
                    types: ["png", "jpg", "jpeg"],
                  },
                  toolbar: [
                    "imageStyle:block",
                    "imageStyle:side",
                    "|",
                    "toggleImageCaption",
                    "imageTextAlternative",
                  ],
                },
                placeholder: "Enter description",
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "imageUpload",
                ],
              }}
              onReady={(editor) => {
                // eslint-disable-next-line no-param-reassign
                editor.plugins.get("FileRepository").createUploadAdapter = (
                  loader
                ) => {
                  return new UploadAdapter(loader, handleImageUpload(editor));
                };
              }}
            />
            {insightsCount !== 0 && (
              <Text color="gray.500">Users {insightsCount}</Text>
            )}
            {!isRoot && (
              <Select
                onBlur={() =>
                  hasUnsavedChanges &&
                  (isNewProblem ? handleSubmit() : handleUpdate())
                }
                onChange={(event) => setProblemType(event.target.value)}
                value={problemType}
                fontSize="xs"
                lineHeight="xs"
                w="fit-content"
                border="none"
                cursor="pointer"
                variant="unstyled"
                iconSize="16px"
                borderRadius={0}
              >
                <option value="problem">Problem</option>
                <option value="opportunity">Opportunity</option>
              </Select>
            )}
          </Flex>
          <Flex flexDir="column" gap="4px">
            <Flex justifyContent="space-between">
              <Text textStyle="bodyMedS" color="gray.500">
                Attached Insights
              </Text>
              <Text
                textStyle="bodyS"
                color="gray.400"
                _hover={{ cursor: "pointer" }}
                onClick={handleAddInsight}
              >
                Add New Insight +
              </Text>
            </Flex>
            <Flex
              flexDir="column"
              gap="4px"
              overflow="auto"
              paddingBottom="4px"
            >
              {insights?.length ? (
                insights.map((insight, i) => (
                  <Insight
                    key={i}
                    insight={insight}
                    handleDelete={handleRemoveInsight}
                    handleUpdate={handleUpdateInsight}
                  />
                ))
              ) : (
                <Solution
                  color="gray.400"
                  backgroundColor="gray.50"
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderColor="gray.200"
                  cursor="default"
                >
                  <Icon as={IdeaActiveIcon} />
                  No insights have been added... yet
                </Solution>
              )}
            </Flex>
          </Flex>
          <Flex flexDir="column" gap="4px">
            {isLeafNode && !solutions?.length && (
              <Hint>
                Add several ideas to this problem to start the ball rolling.
              </Hint>
            )}
            {isLeafNode && solutions?.length === 1 && (
              <Hint>
                Having only 1 idea almost always means you haven’t thought
                enough about the problem yet. Spend a few more minutes, if
                you’re blocked use an AI to generate a lot of alternate solution
                strategies.
              </Hint>
            )}
            {!isLeafNode && !!solutions?.length && (
              <Hint>
                Higher level problems or opportunities should only have ideas
                that are transformative, measure this by asking yourself would
                this idea if successful potentially solve all sub problems.
              </Hint>
            )}
            <Flex justifyContent="space-between">
              <Text textStyle="bodyMedS" color="gray.500">
                Attached Ideas
              </Text>
              <Text
                textStyle="bodyS"
                color="gray.400"
                _hover={{ cursor: "pointer" }}
                onClick={handleAddIdea}
              >
                Add New Idea +
              </Text>
            </Flex>
            <Flex flexDir="column" gap="4px" overflow="auto">
              {solutions?.length ? (
                <SolutionsList solutions={solutions} />
              ) : (
                <Solution
                  color="gray.400"
                  backgroundColor="gray.50"
                  borderWidth="1px"
                  borderStyle="dashed"
                  borderColor="gray.200"
                  cursor="default"
                >
                  <Icon as={IdeaActiveIcon} />
                  No ideas have been added... yet
                </Solution>
              )}
            </Flex>
          </Flex>
        </Section>
        <Box
          p="20px 40px"
          position="fixed"
          bottom={0}
          w="50%"
          backgroundColor="white"
          borderTopColor="gray.200"
          borderTopWidth="1px"
          zIndex={2}
        >
          <Flex justifyContent="space-between">
            <PanelButton
              color="gray.500"
              backgroundColor="gray.50"
              borderColor="gray.200"
              _hover={{
                borderColor: "gray.300",
                backgroundColor: "gray.50",
                color: "gray.600",
              }}
              onClick={() => dispatch({ type: TREE_ACTIONS.closeProblem })}
            >
              Close
            </PanelButton>
            {!isRoot && (
              <PanelButton
                color="red.300"
                backgroundColor="red.50"
                borderColor="red.300"
                _hover={{ backgroundColor: "red.100" }}
                onClick={handleDelete}
                isLoading={loadingDestroy}
              >
                Delete
              </PanelButton>
            )}
          </Flex>
        </Box>
      </Box>
      {isDeleteModalOpen && (
        <ConfirmationModal
          title="Delete problem?"
          onCancel={() => {
            setIsDeleteModalOpen(false);
          }}
          onConfirm={() => {
            deleteProblem();
            setIsDeleteModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export default SidePanel;
