import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function IdeaWinnerIcon() {
  return (
    <Icon viewBox="0 0 12 12" fill="none">
      <path
        d="M3.25 6.5L5 8.25L9 4.25"
        stroke="#77DB89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="11" height="11" rx="1.5" stroke="#5EC269" />
    </Icon>
  );
});
