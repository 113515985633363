import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function IdeaTestingIcon() {
  return (
    <Icon viewBox="0 0 12 12" fill="none">
      <path d="M4 3V9L9 6L4 3Z" stroke="#5F9FFF" strokeLinejoin="round" />
      <rect x="0.5" y="0.5" width="11" height="11" rx="1.5" stroke="#5F9FFF" />
    </Icon>
  );
});
