import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function HomeIcon() {
  return (
    <Icon viewBox="0 0 20 20" fill="none" height="20px" w="20px">
      <path
        d="M10.4203 2.66255C10.3052 2.55781 10.1552 2.49976 9.99961 2.49976C9.844 2.49976 9.69399 2.55781 9.57891 2.66255L3.32891 8.56411C3.26459 8.62276 3.21323 8.69419 3.17811 8.77383C3.143 8.85347 3.12491 8.93957 3.125 9.02661V16.2501C3.125 16.4158 3.19085 16.5748 3.30806 16.692C3.42527 16.8092 3.58424 16.8751 3.75 16.8751H16.25C16.4158 16.8751 16.5747 16.8092 16.6919 16.692C16.8092 16.5748 16.875 16.4158 16.875 16.2501V9.02661C16.875 8.93951 16.8568 8.85338 16.8215 8.77373C16.7863 8.69409 16.7348 8.62269 16.6703 8.56411L10.4203 2.66255Z"
        stroke="#77716D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});
