import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function LinkIcon() {
  return (
    <Icon viewBox="0 0 12 12" fill="none" width="12px" height="12px">
      <path
        d="M4.5 7.5L7.5 4.5"
        stroke="#A7A29E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 3.56761L6.65906 2.16136C7.08218 1.74508 7.65265 1.51286 8.24621 1.51528C8.83976 1.51769 9.40832 1.75456 9.82803 2.17427C10.2477 2.59398 10.4846 3.16254 10.487 3.7561C10.4894 4.34966 10.2572 4.92012 9.84094 5.34324L8.43188 6.74996"
        stroke="#A7A29E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.56749 5.25L2.16124 6.65906C1.74496 7.08218 1.51274 7.65265 1.51516 8.24621C1.51757 8.83976 1.75443 9.40832 2.17415 9.82803C2.59386 10.2477 3.16242 10.4846 3.75598 10.487C4.34953 10.4894 4.92 10.2572 5.34312 9.84094L6.74984 8.43188"
        stroke="#A7A29E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});
