import { useCallback } from "react";
import {
  Button,
  chakra,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

const ModalButton = chakra(Button, {
  baseStyle: {
    borderWidth: "1px",
    borderRadius: "2px",
    p: "8px 10px",
    fontSize: "sm",
    fontWeight: "normal",
    lineHeight: "sm",
    h: "fit-content",
  },
});

const ConfirmationModal = ({ title, description, onConfirm, onCancel }) => {
  const handleClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay
        backgroundColor="rgba(0, 0, 0, 0)"
        backdropFilter="blur(5px)"
      />
      <ModalContent
        borderRadius="2px"
        borderColor="gray.200"
        borderWidth="1px"
        p="28px 32px"
        gap="16px"
      >
        <ModalHeader p={0}>
          <Text textStyle="headingM">{title}</Text>
        </ModalHeader>
        {!!description && (
          <ModalBody p={0}>
            <Text textStyle="bodyS">{description}</Text>
          </ModalBody>
        )}
        <ModalFooter justifyContent="flex-start" gap="10px" p={0}>
          <ModalButton
            onClick={handleConfirm}
            backgroundColor="gray.900"
            borderColor="gray.900"
            color="white"
            _hover={{ backgroundColor: "gray.700", borderColor: "gray.700" }}
          >
            Confirm
          </ModalButton>
          <ModalButton
            onClick={handleClose}
            backgroundColor="gray.50"
            borderColor="gray.200"
            color="gray.500"
            _hover={{
              backgroundColor: "gray.50",
              borderColor: "gray.300",
              color: "gray.600",
            }}
          >
            Dismiss
          </ModalButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationModal;
