import { useEffect, useState } from "react";
import { Flex, Input, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

function Insight({ insight, handleUpdate, handleDelete }) {
  const [label, setLabel] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    setLabel(insight.label);
    setUser(insight.user);
  }, [insight]);

  return (
    <Flex
      p="8px"
      color="gray.600"
      backgroundColor={`gray.50`}
      borderColor={`gray.100`}
      borderRadius="2px"
      borderWidth="1px"
      display="flex"
      alignItems="start"
      gap="12px"
      textStyle="bodyMedXs"
      justifyContent="space-between"
    >
      <div className="flex gap-2">
        <img src="/insights-icon.jpeg" className="w-3 h-3" />
        <div className="flex flex-col">
          <Input
            fontSize="inherit"
            color="gray.600"
            p={0}
            h="fit-content"
            border="none"
            focusBorderColor="rgba(0, 0, 0, 0)"
            borderRadius={0}
            placeholder="Enter insight label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            onBlur={() => handleUpdate(insight.id, label, user)}
          />
          <div className="italic flex gap-1">
            User:
            <Input
              fontSize="inherit"
              fontStyle="italic"
              color="gray.600"
              p={0}
              h="fit-content"
              border="none"
              focusBorderColor="rgba(0, 0, 0, 0)"
              borderRadius={0}
              placeholder="Enter insight user"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              onBlur={() => handleUpdate(insight.id, label, user)}
            />
          </div>
        </div>
      </div>
      <Text onClick={() => handleDelete(insight.id)} cursor="pointer">
        Remove
      </Text>
    </Flex>
  );
}

Insight.propTypes = {
  insight: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default Insight;
