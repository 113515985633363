import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function IdeaActiveIcon() {
  return (
    <Icon viewBox="0 0 12 12" fill="none">
      <path
        d="M8 5C8 6.10457 7.10457 7 6 7C4.89543 7 4 6.10457 4 5C4 3.89543 4.89543 3 6 3C7.10457 3 8 3.89543 8 5Z"
        stroke="#E9A23B"
      />
      <path d="M5 9H7" stroke="#E9A23B" strokeLinecap="round" />
      <rect x="0.5" y="0.5" width="11" height="11" rx="1.5" stroke="#E9A23B" />
    </Icon>
  );
});
