import Hierarchy from "@antv/hierarchy";

import buildTree from "~/application/utils/build_tree";

const withAncestors = (nodes) => {
  if (nodes.length === 0) {
    return nodes;
  }

  const toReturn = [];
  const remaining = [{ ...nodes[0], ancestors: [] }];

  while (remaining.length > 0) {
    const currentNode = remaining.shift();
    toReturn.push(currentNode);
    const children = nodes.filter((n) => n.parentId === currentNode.id);
    children.forEach((n) =>
      remaining.push({
        ...n,
        ancestors: currentNode.ancestors.concat(currentNode.id),
      })
    );
  }

  return toReturn;
};

const withDirectChildren = (nodes) => {
  if (nodes.length === 0) {
    return nodes;
  }

  const toReturn = [];
  const remaining = [{ ...nodes[0], directChildren: [] }];

  while (remaining.length > 0) {
    const currentNode = remaining.shift();
    const children = nodes.filter((n) => n.parentId === currentNode.id);

    toReturn.push({
      ...currentNode,
      directChildren: children
        .filter((n) => n.type === "problem")
        .map((node) => node.id),
    });

    remaining.push(...children);
  }

  return toReturn;
};

const withSiblings = (nodes) => {
  if (nodes.length === 0) {
    return nodes;
  }

  const toReturn = [];
  const remaining = [{ ...nodes[0], siblings: [] }];

  while (remaining.length > 0) {
    const currentNode = remaining.shift();
    const siblings = nodes.filter((n) => n.parentId === currentNode.parentId);
    const children = nodes.filter((n) => n.parentId === currentNode.id);

    toReturn.push({
      ...currentNode,
      // note that in the list of siblings is the node itself (to enable traversing the tree via arrows)
      siblings: siblings
        .filter((node) => node.type === "problem")
        .map((node) => node.id),
    });

    remaining.push(...children);
  }

  return toReturn;
};

const withSolutionStats = (nodes) => {
  if (nodes.length === 0) {
    return nodes;
  }
  const toReturn = [];
  const remaining = [{ ...nodes[0] }];

  while (remaining.length > 0) {
    const currentNode = remaining.shift();
    const children = nodes.filter((n) => n.parentId === currentNode.id);

    if (currentNode.type === "problem") {
      const stats = [];

      children.forEach((c) => {
        if (c.type === "solution") {
          stats.push({
            id: c.id,
            title: c.title,
            state: c.state,
            parentId: c.parentId,
          });
        }
      });

      toReturn.push({
        ...currentNode,
        ...(!!stats.length && { solutionStats: stats }),
      });
    }

    children.forEach((n) => remaining.push(n));
  }

  return toReturn;
};

const withPosition = (nodes) => {
  if (nodes.length === 0) {
    return nodes;
  }

  // From API to `@antv/hierarchy`
  const expand = (node) => ({
    id: node.key,
    children: node.children.map((child) => expand(child)),
  });

  const layoutTree = buildTree(nodes);
  const layoutRoot = { isRoot: true, ...expand(layoutTree) };

  const layout = Hierarchy.compactBox(layoutRoot, {
    direction: "TB",
    getId(d) {
      return d.id;
    },
    getHeight(d) {
      return d.isRoot ? 205 : 150;
    },
    getWidth(d) {
      return d.isRoot ? 480 : 300;
    },
    getHGap() {
      return 25;
    },
    getVGap(d) {
      return d.isRoot ? 40 : 30;
    },
    getSubTreeSep() {
      return 0;
    },
  });

  return nodes.map((node) => {
    let foundNode;

    const { id, ...rest } = node;

    layout.eachNode((layoutNode) => {
      if (layoutNode.id === id) {
        foundNode = layoutNode;
      }
    });

    return {
      id,
      ...rest,
      position: { x: foundNode.x, y: foundNode.y },
    };
  });
};

const toReactFlow = (nodes, selectedNodeId, hiddenNodes) => {
  let minX = nodes.length ? Infinity : 0;
  let minY = nodes.length ? Infinity : 0;
  let maxX = nodes.length ? -Infinity : 0;
  let maxY = nodes.length ? -Infinity : 0;

  nodes
    .filter((node) => node.type === "problem")
    .forEach((node) => {
      const { x, y } = node.position;
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    });

  const buildNode = ({ id, type, position, ...data }) => {
    return {
      id,
      type: "problemNode",
      position: {
        x: position.x,
        y: position.y + (data.ancestors ? data.ancestors.length * 120 : 0),
      },
      data,
      hidden: !!(hiddenNodes && hiddenNodes.includes(id)),
      selected: id === selectedNodeId,
    };
  };

  const buildEdge = ({ source, target }) => {
    return {
      id: `${source}-${target}`,
      type: "smoothstep",
      source,
      target,
      hidden: !!(hiddenNodes && hiddenNodes.includes(target)),
      pathOptions: { borderRadius: 10 },
    };
  };

  const reactFlowNodes = nodes
    .filter((node) => node.type === "problem")
    .map((node) => buildNode(node));

  const reactFlowEdges = nodes.slice(1).map((node) =>
    buildEdge({
      source: node.parentId,
      target: node.id,
      ...node,
    })
  );

  return { reactFlowNodes, reactFlowEdges, minX, minY, maxX, maxY };
};

const calculateLayout = (nodes, selectedNodeId, hiddenNodes) =>
  toReactFlow(
    withPosition(
      withSolutionStats(withSiblings(withDirectChildren(withAncestors(nodes))))
    ),
    selectedNodeId,
    hiddenNodes
  );

export {
  calculateLayout,
  toReactFlow,
  withAncestors,
  withDirectChildren,
  withPosition,
  withSiblings,
  withSolutionStats,
};
