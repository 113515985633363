import { createContext, useContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";

import {
  EDIT_QUERY_PARAM,
  FOCUS_QUERY_PARAM,
} from "~/application/utils/constants";
import { get } from "~/application/utils/fetch";
import {
  addQueryParam,
  getQueryParam,
  removeQueryParam,
} from "~/application/utils/query-params";
import TreesAPI from "~/routes/api/trees";

export const TreeContext = createContext(null);

export const TREE_ACTIONS = {
  addProblem: "ADD_PROBLEM",
  addSolution: "ADD_SOLUTION",
  openProblem: "OPEN_PROBLEM",
  focusProblem: "FOCUS_PROBLEM",
  selectProblem: "SELECT_PROBLEM",
  exitFocusProblem: "EXIT_FOCUS_PROBLEM",
  closeProblem: "CLOSE_PROBLEM",
  updateProblem: "UPDATE_PROBLEM",
  updateSolution: "UPDATE_SOLUTION",
  deleteProblem: "DELETE_PROBLEM",
  deleteSolution: "DELETE_SOLUTION",
  updateParent: "UPDATE_PARENT",
  replaceTree: "REPLACE_TREE",
  enterSelectParentMode: "ENTER_SELECT_PARENT_MODE",
  exitSelectParentMode: "EXIT_SELECT_PARENT_MODE",
  saveViewport: "SAVE_VIEWPORT",
  setRootMetric: "SET_ROOT_METRIC",
  startEditingProblemTitle: "START_EDITING_PROBLEM_TITLE",
  stopEditingProblemTitle: "STOP_EDITING_PROBLEM_TITLE",
  setHiddenNodes: "SET_HIDDEN_NODES",
};

const treeReducer = (state, action) => {
  switch (action.type) {
    case TREE_ACTIONS.addProblem: {
      return { ...state, nodes: state.nodes.concat(action.node) };
    }
    case TREE_ACTIONS.addSolution: {
      return {
        ...state,
        nodes: state.nodes.concat(action.node),
        // if there is an open problem with id same as solution parentId, add to solutions
        ...(!!state.openProblem &&
          state.openProblem.id === action.node.parentId && {
            openProblem: {
              ...state.openProblem,
              solutionStats: [
                ...(state.openProblem.solutionStats ?? []),
                action.node,
              ],
            },
          }),
      };
    }
    case TREE_ACTIONS.openProblem: {
      return {
        ...state,
        changeParentMode: {},
        openProblem: action.problem,
      };
    }
    case TREE_ACTIONS.focusProblem: {
      return {
        ...state,
        changeParentMode: {},
        focusedProblem: action.id,
      };
    }
    case TREE_ACTIONS.selectProblem: {
      return {
        ...state,
        selectedProblem: action.problem,
      };
    }
    case TREE_ACTIONS.exitFocusProblem: {
      return {
        ...state,
        changeParentMode: {},
        focusedProblem: null,
      };
    }
    case TREE_ACTIONS.closeProblem: {
      return {
        ...state,
        changeParentMode: {},
        openProblem: null,
      };
    }
    case TREE_ACTIONS.updateProblem: {
      return {
        ...state,
        nodes: state.nodes.map((m) =>
          m.id === action.node.id ? action.node : m
        ),
        ...(state.openProblem?.id === action.node.id && {
          openProblem: {
            ...state.openProblem,
            title: action.node.title,
            context: action.node.context,
            problemType: action.node.problemType,
          },
        }),
      };
    }
    case TREE_ACTIONS.updateSolution: {
      return {
        ...state,
        nodes: state.nodes.map((m) =>
          m.id === action.node.id ? action.node : m
        ),
        // if there is an open problem with id same as solution parentId, update it
        ...(!!state.openProblem?.solutionStats &&
          state.openProblem.id === action.node.parentId && {
            openProblem: {
              ...state.openProblem,
              solutionStats: state.openProblem.solutionStats.map((solution) =>
                solution.id === action.node.id ? action.node : solution
              ),
            },
          }),
      };
    }
    case TREE_ACTIONS.deleteProblem: {
      return {
        ...state,
        nodes: state.nodes.filter((m) => !action.ids.includes(m.id)),
      };
    }
    case TREE_ACTIONS.deleteSolution: {
      return {
        ...state,
        nodes: state.nodes.filter((m) => !action.ids.includes(m.id)),
        // if there is an open problem, and it contains solution which should be deleted, remove it
        ...(!!state.openProblem &&
          state.openProblem.solutionStats.some(
            (solution) => action.ids[0] === solution.id
          ) && {
            openProblem: {
              ...state.openProblem,
              solutionStats: state.openProblem.solutionStats.filter(
                (solution) => action.ids[0] !== solution.id
              ),
            },
          }),
      };
    }
    case TREE_ACTIONS.updateParent: {
      return {
        ...state,
        changeParentMode: {},
        nodes: state.nodes.map((m) =>
          m.id === action.node.id ? action.node : m
        ),
      };
    }
    case TREE_ACTIONS.replaceTree: {
      return {
        ...state,
        nodes: action.nodes || [],
      };
    }
    case TREE_ACTIONS.enterSelectParentMode: {
      return {
        ...state,
        changeParentMode: {
          id: action.id,
          reassignPath: action.reassignPath,
          nodeType: action.nodeType,
        },
      };
    }
    case TREE_ACTIONS.exitSelectParentMode: {
      return {
        ...state,
        changeParentMode: {},
      };
    }
    case TREE_ACTIONS.saveViewport: {
      return {
        ...state,
        savedViewport: action.savedViewport,
      };
    }
    case TREE_ACTIONS.setRootMetric: {
      return {
        ...state,
        rootMetric: {
          id: action.id,
          label: action.label,
          unit: action.unit,
        },
      };
    }
    case TREE_ACTIONS.startEditingProblemTitle: {
      return {
        ...state,
        editingProblemTitle: true,
      };
    }
    case TREE_ACTIONS.stopEditingProblemTitle: {
      return {
        ...state,
        editingProblemTitle: false,
      };
    }
    case TREE_ACTIONS.setHiddenNodes: {
      return {
        ...state,
        hiddenNodes: action.hiddenNodes,
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
};

const regex = /^\/trees\/([\w]+)/;
const treeId = window.location.pathname.match(regex)?.[1];

export const TreeProvider = ({ children }) => {
  const [state, dispatch] = useReducer(treeReducer, {
    nodes: [],
    changeParentMode: {},
    savedViewport: null,
    selectedProblem: null,
    editingProblemTitle: false,
    treeId,
    hiddenNodes: [],
  });

  useEffect(() => {
    // on init, check if there's a query param with open/focused problem ID
    const focusedProblemId = getQueryParam(FOCUS_QUERY_PARAM);
    const openProblemId = getQueryParam(EDIT_QUERY_PARAM);

    const fetchJson = async () => {
      const { nodes } = await get(TreesAPI.get.path({ id: treeId }));

      dispatch({ type: TREE_ACTIONS.replaceTree, nodes });

      return nodes;
    };

    fetchJson().then((nodes) => {
      if (nodes[0].metrics?.length) {
        const metric = nodes[0].metrics[0];

        dispatch({
          type: TREE_ACTIONS.setRootMetric,
          id: metric.id,
          label: metric.label,
          unit: metric.unit,
        });
      }

      if (openProblemId) {
        const openProblem = nodes.find((node) => node.id === openProblemId);
        dispatch({
          type: TREE_ACTIONS.openProblem,
          problem: { ...openProblem, isRoot: !openProblem.parentId },
        });
      } else if (focusedProblemId)
        setTimeout(
          () =>
            dispatch({ type: TREE_ACTIONS.focusProblem, id: focusedProblemId }),
          100
        );
    });
  }, []);

  useEffect(() => {
    if (state.focusedProblem)
      addQueryParam(FOCUS_QUERY_PARAM, state.focusedProblem);
    else removeQueryParam(FOCUS_QUERY_PARAM);
  }, [state.focusedProblem]);

  useEffect(() => {
    if (state.openProblem)
      addQueryParam(EDIT_QUERY_PARAM, state.openProblem.id);
    else removeQueryParam(EDIT_QUERY_PARAM);
  }, [state.openProblem]);

  return (
    <TreeContext.Provider value={{ state, dispatch, treeId }}>
      {children}
    </TreeContext.Provider>
  );
};

TreeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useTreeContext = () => {
  const context = useContext(TreeContext);

  if (!context) {
    throw new Error("useTreeContext was used outside of its provider");
  }

  return context;
};
