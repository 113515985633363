import { useCallback, useEffect, useState } from "react";
import {
  Button,
  chakra,
  createToastFn,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

import { useTreeContext } from "~/application/pages/tree/components/tree-context";
import LinkIcon from "~/application/shared/icons/link-icon";
import { useGet } from "~/application/utils/use-fetch";
import TreesAPI from "~/routes/api/trees";

const ModalButton = chakra(Button, {
  baseStyle: {
    borderWidth: "1px",
    borderRadius: "2px",
    p: "8px 10px",
    minW: "unset",
    fontSize: "sm",
    fontWeight: "normal",
    lineHeight: "sm",
    backgroundColor: "gray.900",
    borderColor: "gray.900",
    color: "white",
    _hover: { backgroundColor: "gray.700", borderColor: "gray.700" },
  },
});

const showToast = createToastFn("ltr", {
  duration: 2500,
  clickClosable: true,
  position: "top-right",
});

const ShareModal = ({ onClose }) => {
  const {
    state: { treeId },
  } = useTreeContext();
  const [get, loading] = useGet();
  const [link, setLink] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const { url } = await get(TreesAPI.share.path({ id: treeId }));

        setLink(url);
      } catch (e) {
        showToast({
          title: "Something went wrong with fetching the shareable link",
          status: "error",
        });
      }
    })();
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const addLinkToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(link);
      showToast({
        title: "Successfully added link to clipboard!",
        status: "success",
      });
    } catch (e) {
      showToast({
        title: "Something went wrong with adding link to clipboard",
        status: "error",
      });
    }
  }, [link]);

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay
        backgroundColor="rgba(0, 0, 0, 0)"
        backdropFilter="blur(5px)"
      />
      <ModalContent
        borderRadius="2px"
        borderColor="gray.200"
        borderWidth="1px"
        p="28px 32px"
        gap="16px"
      >
        <ModalHeader p={0}>
          <Text textStyle="headingM">Invite users to this view</Text>
        </ModalHeader>
        <ModalBody p={0}>
          <Text textStyle="bodyS">
            Send your teammates this link and they’ll automatically join your
            team and see this tree.
          </Text>
        </ModalBody>
        <ModalFooter p={0}>
          <Flex gap="6px" alignItems="center" w="full">
            <Input
              backgroundColor="gray.50"
              borderColor="gray.200"
              borderWidth="1px"
              borderRadius="2px"
              fontSize="sm"
              lineHeight="sm"
              focusBorderColor="gray.200"
              value={link}
              readOnly
              dir="rtl"
              flex={1}
              onFocus={(event) => event.target.select()}
            />
            <ModalButton
              leftIcon={<LinkIcon />}
              isLoading={loading}
              onClick={addLinkToClipboard}
            >
              Copy Share Link
            </ModalButton>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ShareModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ShareModal;
