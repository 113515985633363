import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function IdeaAssumptionIcon() {
  return (
    <Icon viewBox="0 0 12 12" fill="none">
      <path
        d="M6 9C6.27614 9 6.5 8.77614 6.5 8.5C6.5 8.22386 6.27614 8 6 8C5.72386 8 5.5 8.22386 5.5 8.5C5.5 8.77614 5.72386 9 6 9Z"
        fill="#A847CA"
      />
      <path
        d="M6 6.75V6.125C6.82828 6.125 7.5 5.53719 7.5 4.8125C7.5 4.08781 6.82828 3.5 6 3.5C5.17172 3.5 4.5 4.08781 4.5 4.8125V5"
        stroke="#A847CA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="11" height="11" rx="1.5" stroke="#A847CA" />
    </Icon>
  );
});
