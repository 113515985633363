import "whatwg-fetch";

import getCsrfToken from "./csrf";
import { ReactError } from "./errors";
import { camelCaseKeys, snakeCaseKeys } from "./transform-object-keys";

class FetchError extends ReactError {
  constructor(message, status, ...params) {
    super(message, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.status = status;
  }
}

const handleJsonResponse = (request) =>
  request
    .then((response) => {
      if (response.ok || response.status === 422) return response;

      return Promise.reject(
        new FetchError(`Fetch failed: ${response.statusText}`, response.status)
      );
    })
    .then((response) => response.json())
    .then(camelCaseKeys);

const getHeaders = (isFormData) => {
  if (isFormData) {
    return {
      Accept: "application/json",
      "X-CSRF-Token": getCsrfToken(),
      "X-Requested-With": "XMLHttpRequest",
    };
  }
  return {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
    "X-CSRF-Token": getCsrfToken(),
    "X-Requested-With": "XMLHttpRequest",
  };
};

const withDefaults = (options, isFormData) => {
  const headers = getHeaders(isFormData);

  return {
    headers,
    cache: "no-store",
    credentials: "same-origin",
    redirect: "follow",
    ...options,
  };
};

export const post = (url, data = {}, isFormData = false) =>
  handleJsonResponse(
    fetch(
      url,
      withDefaults(
        {
          method: "POST",
          body: isFormData ? data : JSON.stringify(snakeCaseKeys(data)),
        },
        isFormData
      )
    )
  );

export const patch = (url, data = {}) =>
  handleJsonResponse(
    fetch(
      url,
      withDefaults({
        method: "PATCH",
        body: JSON.stringify(snakeCaseKeys(data)),
      })
    )
  );

export const get = (url) =>
  handleJsonResponse(
    fetch(
      url,
      withDefaults({
        method: "GET",
      })
    )
  );

export const destroy = (url, data = {}) =>
  handleJsonResponse(
    fetch(
      url,
      withDefaults({
        method: "DELETE",
        body: JSON.stringify(snakeCaseKeys(data)),
      })
    )
  );
