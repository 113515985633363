import { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, { Background, getRectOfNodes, useReactFlow } from "reactflow";
import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonGroup } from "@chakra-ui/react";

import { useAuth } from "~/application/context/auth";
import KeyboardShortcuts from "~/application/pages/tree/components/keyboard-shortcuts";
import OnboardingModal from "~/application/pages/tree/components/onboarding-modal";
import SidePanel from "~/application/pages/tree/components/side-panel";
import { BOUNDS_VIEWPORT_OFFSET } from "~/application/utils/constants";
import UserAPI from "~/routes/api/users";

import Navbar from "./components/navbar";
import ProblemNode from "./components/problem-node";
import { TREE_ACTIONS, useTreeContext } from "./components/tree-context";
import { calculateLayout } from "./layout";

import "~/application/style/react-flow-reset.css";

const nodeTypes = {
  problemNode: ProblemNode,
};

const Show = () => {
  const { fitView, fitBounds, setViewport } = useReactFlow();

  const {
    state: {
      nodes,
      focusedProblem,
      openProblem,
      changeParentMode,
      savedViewport,
      hiddenNodes,
    },
    dispatch,
  } = useTreeContext();

  const [selectedNode, setSelectedNode] = useState("");
  // TODO: get initial state from BE
  const { user, setOnboardingCompletedAt } = useAuth();

  const updateOnboardingCompletedAt = useCallback(async () => {
    const data = await UserAPI.updateOnboardingCompletedAt();
    setOnboardingCompletedAt(data);
  }, [setOnboardingCompletedAt]);

  const { reactFlowNodes, reactFlowEdges, minX, minY, maxX, maxY } = useMemo(
    () => calculateLayout(nodes, selectedNode, hiddenNodes),
    [nodes, selectedNode, hiddenNodes]
  );

  const handleCloseProblem = useCallback(() => {
    dispatch({ type: TREE_ACTIONS.exitFocusProblem });
  }, [dispatch]);

  const handleExitChangeParentMode = useCallback(() => {
    dispatch({ type: TREE_ACTIONS.exitSelectParentMode });
  }, [dispatch]);

  // zoom in/out on focus toggle
  useEffect(() => {
    if (!focusedProblem) {
      // reset to savedViewport if there is one, else go back to default view
      if (savedViewport) {
        setViewport(savedViewport, { duration: 1_000 });
        dispatch({ type: TREE_ACTIONS.saveViewport, viewport: null });
      } else {
        fitView({ duration: 1_000 });
      }
      return;
    }

    const focusedNodes = reactFlowNodes.filter(
      (node) =>
        node.id === focusedProblem ||
        node.data.ancestors.includes(focusedProblem)
    );

    const { x, y, width, height } = getRectOfNodes(focusedNodes);

    fitBounds(
      {
        x,
        y,
        // needs to be done this way, so that the subgraph is centered
        width: width + BOUNDS_VIEWPORT_OFFSET,
        height: height + BOUNDS_VIEWPORT_OFFSET,
      },
      { duration: 1_000 }
    );
  }, [focusedProblem]);

  return (
    <div style={{ height: "100vh" }}>
      <Navbar />
      <div style={{ height: "calc(100vh - 60px)", display: "flex" }}>
        <ReactFlow
          fitView
          fitViewOptions={{ nodes: [reactFlowNodes[0]], padding: 2 }}
          nodes={reactFlowNodes}
          edges={reactFlowEdges}
          nodeTypes={nodeTypes}
          nodesConnectable={false}
          nodesDraggable={false}
          zoomOnScroll={true}
          zoomOnDoubleClick={false}
          panOnScroll={false}
          panOnDrag={true}
          minZoom={0.7}
          maxZoom={1.1}
          translateExtent={[
            [minX - 1000, minY - 300],
            [maxX + 1200, maxY + 750],
          ]}
        >
          <KeyboardShortcuts selectNode={setSelectedNode} />
          <Box bg="white" pos="absolute" top={3} left={3} zIndex={10}>
            <ButtonGroup variant="outline" size="sm" spacing={2}>
              {focusedProblem && (
                <Button onClick={handleCloseProblem} variant="outline">
                  Exit Focus
                </Button>
              )}
              {changeParentMode.id && (
                <Button
                  onClick={handleExitChangeParentMode}
                  variant={"outline"}
                  leftIcon={<CloseIcon />}
                >
                  Select new parent for {changeParentMode.id}
                </Button>
              )}
            </ButtonGroup>
          </Box>
          <Background gap={20} />
        </ReactFlow>
        {openProblem && <SidePanel />}
        {user && !user.onboardingCompletedAt && (
          <OnboardingModal onCancel={updateOnboardingCompletedAt} />
        )}
      </div>
    </div>
  );
};

export default Show;
