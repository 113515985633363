import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Handle, useReactFlow } from "reactflow";
import {
  Box,
  Button,
  chakra,
  Flex,
  Input,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

import SolutionsPreview from "~/application/pages/tree/components/solutions-preview";
import HelpIcon from "~/application/shared/icons/help-icon";
import { removeFiguresFromHTML } from "~/application/utils/regex";
import { usePatch, usePost } from "~/application/utils/use-fetch";
import ProblemsAPI from "~/routes/api/problems";

import { TREE_ACTIONS, useTreeContext } from "./tree-context";

import "~/application/style/problem-node.css";

const ActionButton = chakra(Button, {
  baseStyle: {
    borderRadius: "2px",
    border: "1px",
    borderColor: "gray.200",
    backgroundColor: "white",
    padding: "5px 8px",
    color: "gray.500",
    textStyle: "bodyXs",
    h: "min-content",
  },
});

const SwapFocusButton = chakra(Button, {
  baseStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textStyle: "bodyXs",
    color: "gray.600",
    padding: "7px 8px",
    height: "fit-content",
    backgroundColor: "gray.50",
    borderColor: "gray.300",
    borderWidth: "1px",
    borderRadius: "1000px",
    _hover: {
      backgroundColor: "gray.50",
    },
  },
});

const ProblemTypes = {
  problem: {
    title: "Problem",
    backgroundColor: "red",
  },
  opportunity: {
    title: "Opportunity",
    backgroundColor: "orange",
  },
  goal: {
    title: "Goal",
    backgroundColor: "green",
  },
};

const ProblemNode = ({
  id,
  data: {
    ancestors,
    parentId,
    context,
    problemType,
    title,
    solutionStats,
    directChildren,
    siblings,
    metrics,
    order,
    isNewProblem: newProblem,
    insights,
  },
  targetPosition,
  sourcePosition,
  selected,
}) => {
  const {
    state: {
      changeParentMode,
      focusedProblem,
      rootMetric,
      selectedProblem,
      nodes,
      hiddenNodes,
    },
    dispatch,
  } = useTreeContext();

  const { getViewport } = useReactFlow();

  const [isHovered, setIsHovered] = useState(false);

  const [patch] = usePatch();
  const [post] = usePost();
  const [patchUpdate] = usePatch();

  const [editedTitle, setEditedTitle] = useState(title);
  const [isNewProblem, setIsNewProblem] = useState(newProblem);
  const [isSelected, setIsSelected] = useState(false);
  const [isAbleToMove, setIsAbleToMove] = useState(true);
  const [showChildren, setShowChildren] = useState(true);

  const titleInputRef = useRef(false);

  // TODO: Ignoring this so we can add back focus soon
  // eslint-disable-next-line no-unused-vars
  const toggleFocusProblem = useCallback(async () => {
    dispatch({
      type: TREE_ACTIONS.saveViewport,
      savedViewport: getViewport(),
    });

    dispatch({
      type: TREE_ACTIONS.focusProblem,
      id,
    });
  }, [id, dispatch, getViewport]);

  const toggleChangeParent = useCallback(async () => {
    dispatch({
      type: TREE_ACTIONS.enterSelectParentMode,
      id,
      reassignPath: ProblemsAPI.reassign.path({ id }),
      nodeType: "problem",
    });
  }, [id, dispatch]);

  const findAllChildren = useCallback((allNodes, parentIdToFind) => {
    const children = [];
    allNodes.forEach((node) => {
      if (node.parentId === parentIdToFind) {
        children.push(node);

        const subChildren = findAllChildren(allNodes, node.id);
        children.push(...subChildren);
      }
    });
    return children;
  }, []);

  const getInsightsCount = useCallback(() => {
    const children = findAllChildren(nodes, id);
    const uniqueUsers = new Set(
      JSON.parse(insights || "[]").map((insight) => insight.user)
    );
    children.forEach((node) => {
      JSON.parse(node.insights || "[]").forEach((insight) => {
        if (!uniqueUsers.has(insight.user)) {
          uniqueUsers.add(insight.user);
        }
      });
    });
    return uniqueUsers.size;
  }, [findAllChildren, id, insights, nodes]);

  const handleAssignParent = useCallback(async () => {
    const { reassignPath, nodeType } = changeParentMode;
    const { node } = await patch(reassignPath, {
      [nodeType]: {
        parentId: id,
      },
    });

    dispatch({ type: TREE_ACTIONS.updateParent, node });
  }, [changeParentMode, patch, id, dispatch]);

  const isChildOf = useCallback(
    (checkId) => [...ancestors, id].includes(checkId),
    [ancestors, id]
  );

  const eligibleParent = useMemo(
    () => !isChildOf(changeParentMode.id),
    [isChildOf, changeParentMode]
  );

  const addSubProblem = useCallback(async () => {
    const { node } = await post(ProblemsAPI.create.path({ treeId: parentId }), {
      problem: {
        parentId: id,
        title: "Your problem",
        context,
        problemType: "problem",
        insights: "[]",
      },
    });

    dispatch({
      type: TREE_ACTIONS.addProblem,
      node: { ...node, isNewProblem: true },
    });
  }, [id, parentId, context, dispatch, post]);

  const editProblem = useCallback(() => {
    dispatch({
      type: TREE_ACTIONS.openProblem,
      problem: {
        id,
        title,
        context,
        problemType,
        metrics,
        solutionStats,
        directChildren,
        isRoot: !parentId,
        insights,
        insightsCount: getInsightsCount(),
      },
    });
  }, [
    dispatch,
    id,
    title,
    context,
    problemType,
    solutionStats,
    parentId,
    directChildren,
    metrics,
    insights,
    getInsightsCount,
  ]);

  const isFocused = useMemo(
    () => focusedProblem && isChildOf(focusedProblem),
    [focusedProblem, isChildOf]
  );

  const exitFocus = useCallback(() => {
    dispatch({ type: TREE_ACTIONS.exitFocusProblem });
  }, [dispatch]);

  const handleClick = useCallback(
    (event) => {
      dispatch({
        type: TREE_ACTIONS.selectProblem,
        problem: {
          id,
          data: {
            title,
            context,
            problemType,
            metrics,
            solutionStats,
            siblings,
            directChildren,
            parentId,
            insights,
            insightsCount: getInsightsCount(),
          },
        },
      });

      if (event.detail !== 2) return;

      editProblem();
    },
    [
      context,
      directChildren,
      dispatch,
      editProblem,
      id,
      metrics,
      parentId,
      problemType,
      siblings,
      solutionStats,
      title,
      insights,
      getInsightsCount,
    ]
  );

  const handleInputTitleBlur = async () => {
    dispatch({ type: TREE_ACTIONS.stopEditingProblemTitle });
    dispatch({
      type: TREE_ACTIONS.updateProblem,
      node: {
        id,
        isNewProblem: false,
        title,
        parentId,
        context,
        metrics,
        problemType,
        type: problemType,
      },
    });
    setIsNewProblem(false);
    if (!editedTitle || editedTitle === title) return setEditedTitle(title);

    const { node } = await patchUpdate(ProblemsAPI.update.path({ id }), {
      problem: {
        id,
        title: editedTitle,
        selected,
        problemType,
      },
    });
    return dispatch({ type: TREE_ACTIONS.updateProblem, node });
  };

  const handleMoveHorizontally = async (direction) => {
    setIsAbleToMove(false);
    const newNodes = [...nodes];
    const currentNodeIndex = nodes.findIndex((node) => node.id === id);
    let siblingNodeIndex;

    if (direction === "left") {
      siblingNodeIndex = nodes.findIndex(
        (node) =>
          node.id ===
          siblings[siblings.findIndex((sibling) => sibling === id) - 1]
      );
    }
    if (direction === "right") {
      siblingNodeIndex = nodes.findIndex(
        (node) =>
          node.id ===
          siblings[siblings.findIndex((sibling) => sibling === id) + 1]
      );
    }

    const { node: currentNode } = await patchUpdate(
      ProblemsAPI.update.path({ id }),
      {
        problem: {
          parentId,
          context,
          problemType,
          title,
          solutionStats,
          insights,
          order: nodes[siblingNodeIndex].order,
        },
      }
    );
    const { node: siblingNode } = await patchUpdate(
      ProblemsAPI.update.path({ id: nodes[siblingNodeIndex].id }),
      {
        problem: {
          parentId: nodes[siblingNodeIndex].parentId,
          context: nodes[siblingNodeIndex].context,
          problemType: nodes[siblingNodeIndex].problemType,
          title: nodes[siblingNodeIndex].title,
          solutionStats: nodes[siblingNodeIndex].solutionStats,
          insights: nodes[siblingNodeIndex].insights,
          order,
        },
      }
    );
    newNodes[currentNodeIndex] = siblingNode;
    newNodes[siblingNodeIndex] = currentNode;
    dispatch({ type: TREE_ACTIONS.replaceTree, nodes: newNodes });
  };

  const findAllChildrenId = (allNodes, parentIdToFind) => {
    const children = [];
    allNodes.forEach((node) => {
      if (node.parentId === parentIdToFind) {
        children.push(node.id);

        const subChildren = findAllChildrenId(allNodes, node.id);
        children.push(...subChildren);
      }
    });
    return children;
  };

  const handleHideChildren = () => {
    const childrenNodes = findAllChildrenId(
      nodes.filter((node) => node.type === "problem"),
      id
    );
    if (showChildren) {
      dispatch({
        type: TREE_ACTIONS.setHiddenNodes,
        hiddenNodes: [...hiddenNodes, ...childrenNodes],
      });
    } else {
      dispatch({
        type: TREE_ACTIONS.setHiddenNodes,
        hiddenNodes: hiddenNodes.filter(
          (node) => !childrenNodes.includes(node)
        ),
      });
    }
    setShowChildren((prev) => !prev);
  };

  // this has to be done by hand since onSelectionChange doesn't get triggered on first selection (╯°□°)╯︵ ┻━┻
  useEffect(() => {
    if (!parentId && directChildren.length === 0)
      dispatch({
        type: TREE_ACTIONS.selectProblem,
        problem: {
          id,
          data: {
            title,
            context,
            problemType,
            metrics,
            solutionStats,
            siblings,
            directChildren,
            parentId,
          },
        },
      });
  }, [
    parentId,
    directChildren,
    dispatch,
    id,
    title,
    context,
    problemType,
    metrics,
    solutionStats,
    siblings,
  ]);

  useEffect(() => {
    if (selectedProblem && selectedProblem.id === id) setIsSelected(true);
    else setIsSelected(false);
  }, [selectedProblem, id]);

  if (isNewProblem) {
    // This has to be wrapped within setTimeout because of how the react-flow rerenders nodes. Using timeout ensures that the DOM has updated so we can focus the proper input
    setTimeout(() => {
      if (titleInputRef.current) {
        titleInputRef.current.focus();
      }
    }, 0);
  }

  return (
    <Box position="relative" onClick={handleClick}>
      {!!ancestors.length && (
        <Handle type="target" position={targetPosition} isConnectable={false} />
      )}
      <Box
        w={!parentId ? "480px" : "300px"}
        bg="white"
        borderWidth="1px"
        borderColor={`gray.${isSelected ? 500 : 200}`}
        borderRadius="4px"
        borderBottom="none"
        borderBottomRadius={isSelected ? 0 : "2px"}
        boxShadow="base"
        cursor="pointer"
        opacity={isFocused === false ? 0.4 : 1}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        filter={isFocused === false && isHovered ? "blur(4px)" : ""}
        onClick={isFocused === false ? exitFocus : () => {}}
      >
        <Stack alignItems="start">
          <Stack spacing={2} w="full" p="16px 20px" position="relative">
            {changeParentMode.id === id &&
              siblings.findIndex((sibling) => sibling === id) <
                siblings.length - 1 && (
                <Button
                  colorScheme="purple"
                  position="absolute"
                  size="sm"
                  top="50%"
                  right="-40px"
                  pointerEvents="all"
                  onClick={() => handleMoveHorizontally("right")}
                  display="flex"
                  alignItems="center"
                  marginTop="8px"
                  paddingBottom="3px"
                  disabled={!isAbleToMove}
                >
                  &gt;
                </Button>
              )}
            {changeParentMode.id === id &&
              siblings.findIndex((sibling) => sibling === id) !== 0 && (
                <Button
                  colorScheme="purple"
                  position="absolute"
                  size="sm"
                  top="50%"
                  left="-40px"
                  pointerEvents="all"
                  onClick={() => handleMoveHorizontally("left")}
                  display="flex"
                  alignItems="center"
                  paddingBottom="3px"
                  disabled={!isAbleToMove}
                >
                  &lt;
                </Button>
              )}
            {isNewProblem ? (
              <Input
                ref={titleInputRef}
                value={editedTitle}
                onChange={(e) => {
                  setEditedTitle(e.target.value);
                }}
                textStyle={!parentId ? "headingL" : "headingM"}
                disabled={!isNewProblem}
                _disabled={{
                  color: "gray.700",
                }}
                borderWidth="0px"
                onFocus={() => {
                  dispatch({
                    type: TREE_ACTIONS.startEditingProblemTitle,
                  });
                }}
                onBlur={handleInputTitleBlur}
              />
            ) : (
              <Text
                textStyle={!parentId ? "headingL" : "headingM"}
                color="gray.900"
                maxW={!parentId ? 450 : 270}
                noOfLines={3}
              >
                {title}
              </Text>
            )}
            {directChildren.length > 4 && (
              <Tooltip
                hasArrow
                label="Trees don’t work well when they’re super wide on one level. Group these nodes to make the tree more comprehensible."
                color="gray.700"
                backgroundColor="yellow.50"
                borderColor="yellow.100"
                borderWidth="1px"
                borderRadius="2px"
                p="8px"
                fontSize="sm"
                fontWeight="normal"
                lineHeight="sm"
              >
                <Box position="absolute" right="6px" top="-6px">
                  <HelpIcon />
                </Box>
              </Tooltip>
            )}
            {!parentId && context && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: removeFiguresFromHTML(context),
                }}
                textStyle="bodyS"
                noOfLines={3}
              />
            )}
            {!!solutionStats?.length && (
              <SolutionsPreview solutions={solutionStats} />
            )}
            <Flex>
              {!parentId && !!rootMetric && <Tag>{rootMetric.label}</Tag>}
              {!!parentId && getInsightsCount() !== 0 && (
                <Tag>{getInsightsCount()}</Tag>
              )}
              <Spacer />
              <Tag size="md" variant="subtle">
                {ProblemTypes[problemType]?.title}
              </Tag>
            </Flex>
            {changeParentMode.id && eligibleParent && (
              <Button
                colorScheme="purple"
                position="absolute"
                size="sm"
                bottom="-40px"
                left="115px"
                pointerEvents="all"
                onClick={handleAssignParent}
              >
                Select
              </Button>
            )}
          </Stack>

          {isSelected && (
            <Flex
              bg="gray.50"
              border="1px"
              borderColor="gray.500"
              borderTopColor="gray.200"
              borderBottomRadius="4px"
              justifyContent="space-between"
              w="full"
              p="12px 20px"
              position="absolute"
              bottom="-52px"
              left={0}
            >
              <Flex gap="4px">
                <ActionButton onClick={addSubProblem}>[A]dd</ActionButton>

                {!changeParentMode.id && parentId && (
                  <ActionButton onClick={toggleChangeParent}>
                    [M]ove
                  </ActionButton>
                )}

                <ActionButton onClick={editProblem}>[E]dit</ActionButton>
              </Flex>

              {/* Remove for now until focus is more defined
                <ActionButton justifySelf="flex-end" onClick={toggleFocusProblem}>
                  [F]ocus
                </ActionButton> */}
            </Flex>
          )}
        </Stack>
      </Box>
      {isHovered && isFocused === false && (
        <SwapFocusButton
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={exitFocus}
        >
          View whole tree
        </SwapFocusButton>
      )}
      <Handle type="source" position={sourcePosition} isConnectable={false} />
      {directChildren.length > 0 && (
        <Button
          position="absolute"
          left="50%"
          bottom="-100px"
          borderWidth="1px"
          borderColor="gray.200"
          bg="white"
          borderRadius="25px"
          className="-translate-x-1/2 z-50"
          onClick={handleHideChildren}
          fontWeight="normal"
        >
          <Text>{showChildren ? "Hide Branch" : "View Branch"}</Text>
        </Button>
      )}
    </Box>
  );
};

ProblemNode.displayName = "ProblemNode";

ProblemNode.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  targetPosition: PropTypes.string.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default memo(ProblemNode);
