import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function HelpIcon() {
  return (
    <Icon viewBox="0 0 24 24" fill="none" height="20px" w="20px">
      <path
        d="M12 17.625C12.4142 17.625 12.75 17.2892 12.75 16.875C12.75 16.4608 12.4142 16.125 12 16.125C11.5858 16.125 11.25 16.4608 11.25 16.875C11.25 17.2892 11.5858 17.625 12 17.625Z"
        fill="#E9A23B"
      />
      <path
        d="M12 13.5V12.75C13.6566 12.75 15 11.5744 15 10.125C15 8.67562 13.6566 7.5 12 7.5C10.3434 7.5 9 8.67562 9 10.125V10.5"
        stroke="#E9A23B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="#E9A23B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});
