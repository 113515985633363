import { useMemo } from "react";
import { chakra, Icon, Stack, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";

import IdeaActiveIcon from "~/application/shared/icons/idea-active-icon";
import IdeaAssumptionIcon from "~/application/shared/icons/idea-assumption-icon";
import IdeaLoserIcon from "~/application/shared/icons/idea-loser-icon";
import IdeaTestingIcon from "~/application/shared/icons/idea-testing-icon";
import IdeaWinnerIcon from "~/application/shared/icons/idea-winner-icon";

export const SOLUTIONS_THEME = {
  idea: {
    color: "yellow",
    icon: IdeaActiveIcon,
  },
  testing: {
    color: "blue",
    icon: IdeaTestingIcon,
  },
  winner: {
    color: "green",
    icon: IdeaWinnerIcon,
  },
  loser: {
    color: "red",
    icon: IdeaLoserIcon,
  },
  assumption: {
    color: "purple",
    icon: IdeaAssumptionIcon,
  },
};

export const Solution = chakra("div", {
  baseStyle: {
    p: "8px",
    borderRadius: "2px",
    borderWidth: "1px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    textStyle: "bodyMedXs",
  },
});

const SolutionsPreview = ({ solutions }) => {
  const maximisedSolutions = useMemo(() => {
    if (solutions.length < 3) return solutions;
    return solutions.slice(0, 1);
  }, [solutions]);

  const minimisedSolutions = useMemo(() => {
    if (solutions.length < 3) return [];
    return solutions.slice(1, solutions.length);
  }, [solutions]);

  return (
    <Stack spacing="8px" w="100%" direction="column">
      <Stack spacing="2px" w="100%" direction="column">
        {maximisedSolutions.map(
          ({ title: solutionTitle, state, id: solutionId }) => {
            const { color, icon } = SOLUTIONS_THEME[state];

            return (
              <Solution
                key={solutionId}
                color="gray.600"
                backgroundColor={`${color}.50`}
                borderColor={`${color}.100`}
              >
                <Icon as={icon} />
                <Text noOfLines={1}>{solutionTitle}</Text>
              </Solution>
            );
          }
        )}
      </Stack>
      {!!minimisedSolutions.length && (
        <Stack direction="row" w="100%" spacing="2px" mt="14px">
          {minimisedSolutions.map(({ state, id: solutionId }) => {
            const { icon } = SOLUTIONS_THEME[state];

            return <Icon key={solutionId} as={icon} />;
          })}
        </Stack>
      )}
    </Stack>
  );
};

SolutionsPreview.propTypes = {
  solutions: PropTypes.array.isRequired,
};

export default SolutionsPreview;
