import { createRoot } from "react-dom/client";

import App from "../application/app";
import Trees from "../application/trees";

const container = document.getElementById("react-root");
const treesContainer = document.getElementById("react-root-trees");

if (treesContainer) {
  const root = createRoot(treesContainer);

  root.render(<Trees />);
}

if (container) {
  const root = createRoot(container);

  root.render(<App />);
}
