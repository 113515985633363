// JsFromRoutes CacheKey 710c0e5956df68a8c5f385438988e5d5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  create: definePathHelper("post", "/api/trees/:tree_id/solutions"),
  reassign: definePathHelper("patch", "/api/solutions/:id/reassign"),
  update: definePathHelper("patch", "/api/solutions/:id"),
  destroy: definePathHelper("delete", "/api/solutions/:id"),
};
