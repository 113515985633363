export class UploadAdapter {
  constructor(loader, handleUpload) {
    this.loader = loader;
    this.handleUpload = handleUpload;
  }

  upload() {
    return this.loader.file.then((file) => this.handleUpload(file));
  }
}
