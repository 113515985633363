import { useCallback, useState } from "react";
import { getOutgoers, getRectOfNodes, useReactFlow } from "reactflow";
import {
  Button,
  ButtonGroup,
  chakra,
  Flex,
  Link,
  Text,
} from "@chakra-ui/react";

import ShareModal from "~/application/pages/tree/components/share-modal";
import { useTreeContext } from "~/application/pages/tree/components/tree-context";
import HomeIcon from "~/application/shared/icons/home-icon";
import ShareIcon from "~/application/shared/icons/share-icon";
import TreeIcon from "~/application/shared/icons/tree-icon";

const NavButton = chakra(Button, {
  baseStyle: {
    backgroundColor: "gray.50",
    borderRadius: "2px",
    borderWidth: "1px",
    borderColor: "gray.100",
    height: "fit-content",
    width: "fit-content",
    outline: "none",
    outlineOffset: "unset",
    minW: "unset",
    p: "6px",
  },
});

const Navbar = () => {
  const {
    state: { nodes },
  } = useTreeContext();

  const { getNode, getNodes, getEdges, fitBounds } = useReactFlow();

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const showRootView = useCallback(() => {
    const rootNode = getNode(nodes[0].id);
    // fit bounds so the root and its direct children are visible
    fitBounds(
      getRectOfNodes([
        ...getOutgoers(rootNode, getNodes(), getEdges()),
        rootNode,
      ]),
      { duration: 1_000 }
    );
  }, [fitBounds, nodes, getNode, getNodes, getEdges]);

  return (
    <>
      <Flex
        w="full"
        p="12px"
        backgroundColor="white"
        zIndex={1}
        borderBottom="1px"
        borderColor="gray.200"
        justifyContent="space-between"
      >
        <ButtonGroup>
          <Link href="/trees">
            <NavButton>
              <HomeIcon />
            </NavButton>
          </Link>
          <NavButton onClick={showRootView}>
            <TreeIcon />
          </NavButton>
          <Text
            textStyle="bodyXs"
            color="gray.500"
            backgroundColor="gray.50"
            borderRadius="2px"
            borderWidth="1px"
            borderColor="gray.100"
            h="34px"
            w="34px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            Beta
          </Text>
        </ButtonGroup>
        <ButtonGroup>
          <NavButton p="8px" onClick={() => setIsShareModalOpen(true)}>
            <ShareIcon />
            <Text textStyle="bodyXs" color="gray.500" marginLeft="4px">
              Share Project
            </Text>
          </NavButton>
          {/* <NavButton p="8px">
            <UserIcon />
          </NavButton> */}
        </ButtonGroup>
      </Flex>
      {isShareModalOpen && (
        <ShareModal onClose={() => setIsShareModalOpen(false)} />
      )}
    </>
  );
};

export default Navbar;
