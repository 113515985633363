class TreeModel {
  constructor(key, parentId) {
    this.key = key;
    this.parentId = parentId;
    this.childrenKeyMap = {};
    this.children = [];
  }

  addChildren(child) {
    if (!this.children) {
      this.children = [];
    }
    this.children.push(child);
    this.childrenKeyMap[child.key] = true;
  }
}

const buildTree = (nodeList) => {
  const idMapping = nodeList.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
  }, {});

  const idMapMaps = {};

  nodeList.forEach((node) => {
    if (!(node.parentId in idMapMaps)) {
      idMapMaps[node.parentId] = {};
    }
    idMapMaps[node.parentId][node.id] = true;
  });

  let root = nodeList[idMapping[Object.keys(idMapMaps.null)[0]]];
  root = new TreeModel(root.id, root.parentId);
  const q = [root];
  while (q.length) {
    const lenQ = q.length;
    for (let i = 0; i < lenQ; i += 1) {
      const curr = q.shift();
      if (curr.key in idMapMaps) {
        Object.keys(idMapMaps[curr.key]).forEach((child) => {
          const thisTreeModel = new TreeModel(
            nodeList[idMapping[child]].id,
            nodeList[idMapping[child]].parentId
          );
          curr.addChildren(thisTreeModel);

          q.push(thisTreeModel);
        });
      }
    }
  }
  return root;
};

export default buildTree;
