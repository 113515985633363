import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function IdeaLoserIcon() {
  return (
    <Icon viewBox="0 0 12 12" fill="none">
      <path
        d="M8.25 3.75L3.75 8.25"
        stroke="#DD524C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 8.25L3.75 3.75"
        stroke="#DD524C"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="11" height="11" rx="1.5" stroke="#DD524C" />
    </Icon>
  );
});
