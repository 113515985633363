import { ChakraProvider } from "@chakra-ui/react";

import { AuthProvider } from "./context/auth";
import Tree from "./routes/tree";
import ErrorBoundary from "./utils/error-boundary";
import theme from "./theme";

import "@fontsource/lora/400.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "reactflow/dist/style.css";

const App = () => (
  <ErrorBoundary>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Tree />
      </AuthProvider>
    </ChakraProvider>
  </ErrorBoundary>
);

export default App;
