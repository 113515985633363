import { memo, useCallback, useState } from "react";
import { Box, Button, Flex, Icon, Select } from "@chakra-ui/react";
import PropTypes from "prop-types";

import {
  Solution,
  SOLUTIONS_THEME,
} from "~/application/pages/tree/components/solutions-preview";
import {
  TREE_ACTIONS,
  useTreeContext,
} from "~/application/pages/tree/components/tree-context";
import AutoResizeTextArea from "~/application/shared/components/auto-resize-text-area";
import { useDestroy, usePatch } from "~/application/utils/use-fetch";
import SolutionsAPI from "~/routes/api/solutions";

const SolutionsList = ({ solutions }) => {
  const { dispatch } = useTreeContext();
  const [patchUpdate] = usePatch();
  const [destroy, loadingDestroy] = useDestroy();

  const [newTitle, setNewTitle] = useState(null);

  const updateSolution = useCallback(
    async (id, title, state) => {
      const { node } = await patchUpdate(SolutionsAPI.update.path({ id }), {
        solution: {
          title,
          state,
        },
      });

      dispatch({ type: TREE_ACTIONS.updateSolution, node });
    },
    [patchUpdate, dispatch]
  );

  const persistNewTitle = useCallback(
    (id, state) => () => {
      if (newTitle === null) return;

      updateSolution(id, newTitle, state);
    },
    [newTitle, updateSolution]
  );

  const handleStateChange = useCallback(
    (id, title) => (event) => {
      updateSolution(id, title, event.target.value);
    },
    [updateSolution]
  );

  const handleDelete = useCallback(
    (id) => async () => {
      const { ids } = await destroy(SolutionsAPI.destroy.path({ id }));

      dispatch({ type: TREE_ACTIONS.deleteSolution, ids });
    },
    [destroy, dispatch]
  );

  return (
    <>
      {solutions.map(({ id: solutionId, title: solutionTitle, state }) => {
        const { color, icon } = SOLUTIONS_THEME[state];

        return (
          <Solution
            key={solutionId}
            color={`${color}.${state === "testing" ? "500" : "300"}`}
            borderColor={`${color}.100`}
            backgroundColor={`${color}.50`}
            alignItems="flex-start"
          >
            <Box
              as="span"
              h="fit-content"
              display="flex"
              alignItems="flex-start"
              mt="1px"
            >
              <Icon as={icon} />
            </Box>
            <Flex flexDir="column" gap="4px" w="full">
              <Select
                onChange={handleStateChange(solutionId, solutionTitle)}
                value={state}
                fontSize="xs"
                lineHeight="xs"
                w="fit-content"
                border="none"
                cursor="pointer"
                variant="unstyled"
                iconSize="16px"
                borderRadius={0}
              >
                <option value="idea">Solution</option>
                <option value="testing">Testing</option>
                <option value="winner">Winner</option>
                <option value="loser">Loser</option>
                <option value="assumption">Assumption</option>
              </Select>
              <AutoResizeTextArea
                defaultValue={solutionTitle}
                fontSize="inherit"
                color="gray.600"
                p={0}
                h="fit-content"
                border="none"
                focusBorderColor="rgba(0, 0, 0, 0)"
                borderRadius={0}
                placeholder="Enter idea title"
                _placeholder={{ color: "gray.400" }}
                onChange={(event) => setNewTitle(event.target.value)}
                onBlur={persistNewTitle(solutionId, state)}
              />
            </Flex>
            <Button
              variant="unstyled"
              onClick={handleDelete(solutionId)}
              cursor="pointer"
              fontSize="xs"
              fontWeight="normal"
              minW="unset"
              h="fit-content"
              isLoading={loadingDestroy}
            >
              Remove
            </Button>
          </Solution>
        );
      })}
    </>
  );
};

SolutionsList.propTypes = {
  solutions: PropTypes.array.isRequired,
};

export default memo(SolutionsList);
