import { useMemo, useState } from "react";

import { UserError } from "./errors";
import { destroy, get, patch, post } from "./fetch";

const handleUserPromise = async (promise, loadingHandler) => {
  try {
    loadingHandler(true);

    const result = await promise;

    loadingHandler(false);

    return result;
  } catch (e) {
    loadingHandler(false);

    throw new UserError(e.message, { cause: e });
  }
};

const createRequest =
  (method, loadingHandler) =>
  (...args) => {
    const promise = method(...args);

    return handleUserPromise(promise, loadingHandler);
  };

const useGet = () => {
  const [loading, setLoading] = useState(false);

  const makeRequest = useMemo(() => createRequest(get, setLoading), []);

  return [makeRequest, loading, setLoading];
};

const usePatch = () => {
  const [loading, setLoading] = useState(false);

  const makeRequest = useMemo(() => createRequest(patch, setLoading), []);

  return [makeRequest, loading, setLoading];
};

const usePost = () => {
  const [loading, setLoading] = useState(false);

  const makeRequest = useMemo(() => createRequest(post, setLoading), []);

  return [makeRequest, loading, setLoading];
};

const useDestroy = () => {
  const [loading, setLoading] = useState(false);

  const makeRequest = useMemo(() => createRequest(destroy, setLoading), []);

  return [makeRequest, loading, setLoading];
};

export { useDestroy, useGet, usePatch, usePost };
