import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useGet } from "~/application/utils/use-fetch";
import UsersAPI from "~/routes/api/users";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const setOnboardingCompletedAt = (data) => {
    setUser({
      ...user,
      onboardingCompletedAt: data.onboardingCompletedAt,
    });
  };

  const [get] = useGet();

  useEffect(() => {
    const getUser = async () => {
      const data = await get(UsersAPI.get.path());
      setUser(data);
      setLoaded(true);
    };

    getUser();
  }, [get]);

  const value = {
    loaded,
    user,
    isAuthenticated: !!user,
    setOnboardingCompletedAt,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthProvider, useAuth };
