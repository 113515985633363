import { forwardRef, useCallback } from "react";
import ResizeTextarea from "react-textarea-autosize";
import { Textarea } from "@chakra-ui/react";

const AutoResizeTextArea = (props, ref) => {
  // prevent adding newline
  const handleKeyDown = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  }, []);

  return (
    <Textarea
      minH="unset"
      overflow="hidden"
      w="100%"
      resize="none"
      ref={ref}
      minRows={1}
      transition="height none"
      as={ResizeTextarea}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );
};

export default forwardRef(AutoResizeTextArea);
