import { useCallback } from "react";
import {
  Button,
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

const ModalButton = chakra(Button, {
  baseStyle: {
    borderWidth: "1px",
    borderRadius: "2px",
    p: "8px 10px",
    minW: "unset",
    fontSize: "sm",
    fontWeight: "normal",
    lineHeight: "sm",
    backgroundColor: "gray.900",
    borderColor: "gray.900",
    color: "white",
    _hover: { backgroundColor: "gray.700", borderColor: "gray.700" },
  },
});

const OnboardingModal = ({ onCancel }) => {
  const handleClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Modal isOpen onClose={handleClose}>
      <ModalOverlay
        backgroundColor="rgba(0, 0, 0, 0)"
        backdropFilter="blur(5px)"
      />
      <ModalContent
        borderRadius="2px"
        borderColor="gray.200"
        borderWidth="1px"
        p="28px 32px"
        gap="16px"
      >
        <ModalHeader p={0}>
          <Text textStyle="headingM">
            Trees are simple things. These ones have just 3 flexible parts.
          </Text>
        </ModalHeader>
        <ModalBody p={0} display="flex" flexDir="column" gap="16px">
          <Flex flexDir="column" gap="6px">
            <Text textStyle="bodyS">
              <strong>Problems or opportunities</strong>, however you work, make
              the main nodes of the tree your user-problems or opportunities.
              Write them honestly and regroup to make them make sense and your
              product strategy will reveal itself.
            </Text>
            <Text textStyle="bodyS">
              <strong>Add ideas and test them and track their success</strong>{" "}
              in any problem you can add ideas or solutions. Add many to the
              bottom nodes in particular, that’s where most work is done.
            </Text>
            <Text textStyle="bodyS">
              <strong>Metrics</strong>, track your work however you want. Add
              the outcome you’re tracking at the top of the tree and then size
              your problems at the bottom parts of the tree.
            </Text>
          </Flex>
          <Text textStyle="bodyS">
            That’s it. You’ll see other features help you do your work, but you
            don’t have to learn anything more than the above.
          </Text>
          <Text textStyle="bodyS">
            We believe the power is in simplicity of trees and getting out of
            the way of your core work, not forcing you into any framework
            anyone&apos;s come up with. Frameworks and trends pass on with new
            seasons, trees live long lives. Have fun and tell us what works or
            does not.
          </Text>
        </ModalBody>
        <ModalFooter p={0}>
          <ModalButton
            onClick={handleClose}
            backgroundColor="gray.50"
            borderColor="gray.200"
            color="gray.500"
            _hover={{
              backgroundColor: "gray.50",
              borderColor: "gray.300",
              color: "gray.600",
            }}
          >
            Dismiss
          </ModalButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

OnboardingModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default OnboardingModal;
