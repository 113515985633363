/* eslint-disable max-classes-per-file */

/**
 * Throw this when you want to make it easy to find errors in Sentry with `react:yes`.
 *
 * Pulled from https://developer.mozilla.org/en-US/docs/web/javascript/reference/global_objects/error#custom_error_types
 */
class ReactError extends Error {
  constructor(message, extra = {}, ...params) {
    super(message, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ReactError);
    }

    this.name = "ReactError";
    this.extra = extra;
  }
}

/**
 * Throw this when you want to let the user know something unexpected has gone wrong (and make it easy
 * to find in Sentry with `react:yes`).
 *
 * Pulled from https://developer.mozilla.org/en-US/docs/web/javascript/reference/global_objects/error#custom_error_types
 */
class UserError extends ReactError {
  constructor(...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, UserError);
    }

    this.name = "UserError";
  }
}

export { ReactError, UserError };
