// JsFromRoutes CacheKey 65d1a415b56377bdd5c9a38b5cf7ec0d
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  get: definePathHelper("get", "/api/user"),
  updateOnboardingCompletedAt: definePathHelper(
    "post",
    "/api/user/update_onboarding_completed_at"
  ),
};
