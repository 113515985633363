import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    red: {
      50: "#FCF2F2",
      100: "#F9E3E2",
      300: "#DD524C",
    },
    yellow: {
      50: "#FEFBED",
      100: "#FCF3CC",
      300: "#E9A23B",
    },
    green: {
      50: "#F2FDF5",
      100: "#E2FBE8",
      300: "#5EC269",
    },
    blue: {
      50: "#F1F7FF",
      100: "#DCEAFF",
      300: "#5F9FFF",
    },
    gray: {
      50: "#FAFAF9",
      100: "#F5F5F4",
      200: "#E7E5E4",
      300: "#D5D3D1",
      400: "#A7A29E",
      500: "#77716D",
      600: "#56534F",
      700: "#43403C",
      800: "#282524",
      900: "#1B1917",
    },
    black: "#0C0A09",
  },
  fonts: {
    body: "Inter, sans-serif",
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "20px",
    xl: "24px",
  },
  lineHeights: {
    xs: "14px",
    sm: "18px",
    md: "20px",
    lg: "24px",
    xl: "30px",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
  },
  textStyles: {
    headingL: {
      fontSize: "xl",
      fontWeight: "normal",
      lineHeight: "xl",
      fontFamily: "Lora, serif",
    },
    headingM: {
      fontSize: "lg",
      fontWeight: "normal",
      lineHeight: "lg",
      fontFamily: "Lora, serif",
    },
    headingS: {
      fontSize: "md",
      fontWeight: "normal",
      lineHeight: "sm",
      fontFamily: "Lora, serif",
    },
    bodyM: {
      fontSize: "md",
      fontWeight: "normal",
      lineHeight: "md",
    },
    bodyS: {
      fontSize: "sm",
      fontWeight: "normal",
      lineHeight: "sm",
    },
    bodyXs: {
      fontSize: "xs",
      fontWeight: "normal",
      lineHeight: "xs",
    },
    bodyMedM: {
      fontSize: "md",
      fontWeight: "medium",
      lineHeight: "md",
    },
    bodyMedS: {
      fontSize: "sm",
      fontWeight: "medium",
      lineHeight: "sm",
    },
    bodyMedXs: {
      fontSize: "xs",
      fontWeight: "medium",
      lineHeight: "xs",
    },
  },
});

export default theme;
