import { useEffect } from "react";

const Trees = () => {
  useEffect(() => {
    const elements = document.querySelectorAll(".tree-menu-button");
    const toggleMenu = (el) => {
      const deleteButton = el.querySelector(".tree-delete-button");
      const hasHiddenClass = deleteButton.classList.contains("hidden");

      if (hasHiddenClass) {
        deleteButton.classList.remove("hidden");
      } else {
        deleteButton.classList.add("hidden");
      }
    };

    const toggleDeleteModal = (el) => {
      const deleteModal = el.parentNode.querySelector(".tree-delete-modal");
      const hasHiddenClass = deleteModal.classList.contains("hidden");

      if (hasHiddenClass) {
        deleteModal.classList.remove("hidden");
      } else {
        deleteModal.classList.add("hidden");
      }
    };

    const hideDeleteModal = (deleteModal) => {
      deleteModal.classList.add("hidden");
    };

    elements.forEach((el) => {
      el.addEventListener("click", () => toggleMenu(el));
      const deleteButton = el.querySelector(".tree-delete-button");
      deleteButton.addEventListener("click", () => toggleDeleteModal(el));

      const deleteModal = el.parentNode.querySelector(".tree-delete-modal");
      const cancelButton = el.parentNode.querySelector(".tree-cancel-button");
      cancelButton.addEventListener("click", () => {
        hideDeleteModal(deleteModal);
      });

      const backgroundEl = el.parentNode.querySelector(
        ".tree-modal-background"
      );
      backgroundEl.addEventListener("click", () =>
        hideDeleteModal(deleteModal)
      );
    });

    return () => {
      elements.forEach((el) => {
        el.removeEventListener("click", () => toggleMenu(el));
        const deleteButton = el.querySelector(".tree-delete-button");
        deleteButton.removeEventListener("click", () => toggleDeleteModal(el));

        const deleteModal = el.parentNode.querySelector(".tree-delete-modal");
        const cancelButton = el.parentNode.querySelector(".tree-cancel-button");
        cancelButton.removeEventListener("click", () => {
          hideDeleteModal(deleteModal);
        });

        const backgroundEl = el.parentNode.querySelector(
          ".tree-modal-background"
        );
        backgroundEl.removeEventListener("click", hideDeleteModal(deleteModal));
      });
    };
  }, []);
  return <></>;
};

export default Trees;
