import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function ShareIcon() {
  return (
    <Icon viewBox="0 0 12 12" fill="none" width="12px" height="12px">
      <g clipPath="url(#clip0_134_8327)">
        <path
          d="M0.479492 9.37501C0.854065 8.79908 1.36655 8.32583 1.97043 7.99823C2.5743 7.67062 3.25044 7.49902 3.93746 7.49902C4.62448 7.49902 5.30062 7.67062 5.90449 7.99823C6.50837 8.32583 7.02086 8.79908 7.39543 9.37501"
          stroke="#A7A29E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0625 7.5C8.74948 7.4996 9.42567 7.67091 10.0296 7.99837C10.6335 8.32583 11.146 8.79905 11.5205 9.375"
          stroke="#A7A29E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.9375 7.5C5.28369 7.5 6.375 6.40869 6.375 5.0625C6.375 3.71631 5.28369 2.625 3.9375 2.625C2.59131 2.625 1.5 3.71631 1.5 5.0625C1.5 6.40869 2.59131 7.5 3.9375 7.5Z"
          stroke="#A7A29E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.15723 2.79844C7.49066 2.66545 7.84934 2.60775 8.20766 2.62946C8.56597 2.65117 8.91507 2.75176 9.23 2.92403C9.54493 3.0963 9.81793 3.33601 10.0295 3.62602C10.241 3.91603 10.3859 4.24919 10.4538 4.60169C10.5216 4.95419 10.5108 5.31732 10.4221 5.66515C10.3333 6.01298 10.1688 6.33691 9.94036 6.6138C9.7119 6.89068 9.42511 7.11369 9.10047 7.26689C8.77582 7.42008 8.42135 7.49968 8.06238 7.5"
          stroke="#A7A29E"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_134_8327">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});
