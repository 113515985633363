import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import {
  TREE_ACTIONS,
  useTreeContext,
} from "~/application/pages/tree/components/tree-context";
import { usePost } from "~/application/utils/use-fetch";
import ProblemsAPI from "~/routes/api/problems";

const handleKeyDown =
  (
    aKeyPressCallback,
    eKeyPressCallback,
    mKeyPressCallback,
    fKeyPressCallback,
    escKeyPressCallback,
    upKeyPressCallback,
    downKeyPressCallback,
    leftKeyPressCallback,
    rightKeyPressCallback
  ) =>
  (event) => {
    switch (event.key) {
      case "a":
        aKeyPressCallback();
        break;
      case "e":
        eKeyPressCallback();
        break;
      case "m":
        mKeyPressCallback();
        break;
      case "f":
        fKeyPressCallback();
        break;
      case "Escape":
        escKeyPressCallback();
        break;
      case "ArrowUp":
        upKeyPressCallback();
        break;
      case "ArrowDown":
        downKeyPressCallback();
        break;
      case "ArrowLeft":
        leftKeyPressCallback();
        break;
      case "ArrowRight":
        rightKeyPressCallback();
        break;
      default:
    }
  };

const KeyboardShortcuts = ({ selectNode }) => {
  const {
    state: {
      focusedProblem,
      openProblem,
      selectedProblem,
      editingProblemTitle,
    },
    dispatch,
  } = useTreeContext();

  const [post] = usePost();

  const addSubProblem = useCallback(async () => {
    if (!selectedProblem || openProblem || editingProblemTitle) return;

    const { node } = await post(
      ProblemsAPI.create.path({ treeId: selectedProblem.data.parentId }),
      {
        problem: {
          parentId: selectedProblem.id,
          title: "Your problem",
          context: selectedProblem.data.context,
          problemType: "problem",
          insights: "[]",
        },
      }
    );
    dispatch({
      type: TREE_ACTIONS.addProblem,
      node: { ...node, isNewProblem: true, insights: "[]" },
    });
  }, [selectedProblem, dispatch, openProblem, post, editingProblemTitle]);

  const editProblem = useCallback(() => {
    if (!selectedProblem || openProblem || editingProblemTitle) return;

    dispatch({
      type: TREE_ACTIONS.openProblem,
      problem: {
        id: selectedProblem.id,
        title: selectedProblem.data.title,
        context: selectedProblem.data.context,
        problemType: selectedProblem.data.problemType,
        solutionStats: selectedProblem.data.solutionStats,
        isRoot: !selectedProblem.data.parentId,
        directChildren: selectedProblem.data.directChildren.length,
        insights: selectedProblem.data.insights,
        insightsCount: selectedProblem.data.insightsCount,
      },
    });
  }, [selectedProblem, dispatch, openProblem, editingProblemTitle]);

  const focusProblem = useCallback(() => {
    if (!selectedProblem || openProblem || editingProblemTitle) return;

    dispatch({
      type: TREE_ACTIONS.focusProblem,
      id: selectedProblem.id,
    });
  }, [selectedProblem, dispatch, openProblem, editingProblemTitle]);

  const enterSelectParentMode = useCallback(() => {
    if (!selectedProblem || openProblem || editingProblemTitle) return;

    dispatch({
      type: TREE_ACTIONS.enterSelectParentMode,
      id: selectedProblem.id,
      reassignPath: ProblemsAPI.reassign.path({ id: selectedProblem.id }),
      nodeType: "problem",
    });
  }, [selectedProblem, dispatch, openProblem, editingProblemTitle]);

  const exitState = useCallback(() => {
    if (openProblem)
      dispatch({
        type: TREE_ACTIONS.closeProblem,
      });
    else if (focusedProblem) dispatch({ type: TREE_ACTIONS.exitFocusProblem });
  }, [dispatch, openProblem, focusedProblem]);

  const selectParent = useCallback(() => {
    if (!selectedProblem || openProblem || editingProblemTitle) return;

    selectNode(selectedProblem.data.parentId ?? "");
  }, [selectedProblem, openProblem, selectNode, editingProblemTitle]);

  const selectChild = useCallback(() => {
    if (
      !selectedProblem ||
      !selectedProblem.data.directChildren.length ||
      openProblem
    )
      return;

    selectNode(
      selectedProblem.data.directChildren[
        Math.floor(selectedProblem.data.directChildren.length / 2)
      ] ?? null
    );
  }, [selectedProblem, openProblem, selectNode]);

  const selectLeftSibling = useCallback(() => {
    if (!selectedProblem || openProblem || editingProblemTitle) return;

    const { siblings } = selectedProblem.data;

    const selectedIndex = siblings.findIndex(
      (nodeId) => nodeId === selectedProblem.id
    );

    selectNode(
      siblings[(selectedIndex - 1 + siblings.length) % siblings.length]
    );
  }, [selectedProblem, openProblem, selectNode, editingProblemTitle]);

  const selectRightSibling = useCallback(() => {
    if (!selectedProblem || openProblem || editingProblemTitle) return;

    const { siblings } = selectedProblem.data;

    const selectedIndex = siblings.findIndex(
      (nodeId) => nodeId === selectedProblem.id
    );

    selectNode(
      siblings[(selectedIndex + 1 + siblings.length) % siblings.length]
    );
  }, [selectedProblem, openProblem, selectNode, editingProblemTitle]);

  // KEYBOARD SHORTCUT CALLBACK MAPPER
  const keyPressCallback = useCallback(
    (event) =>
      handleKeyDown(
        addSubProblem,
        editProblem,
        enterSelectParentMode,
        focusProblem,
        exitState,
        selectParent,
        selectChild,
        selectLeftSibling,
        selectRightSibling
      )(event),
    [
      addSubProblem,
      enterSelectParentMode,
      editProblem,
      focusProblem,
      exitState,
      selectParent,
      selectChild,
      selectLeftSibling,
      selectRightSibling,
    ]
  );

  // KEYBOARD SHORTCUT LISTENER SETUP
  useEffect(() => {
    document.addEventListener("keyup", keyPressCallback);

    return () => {
      document.removeEventListener("keyup", keyPressCallback);
    };
  }, [keyPressCallback]);

  return null;
};

KeyboardShortcuts.propTypes = {
  selectNode: PropTypes.func.isRequired,
};

export default KeyboardShortcuts;
