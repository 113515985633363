// JsFromRoutes CacheKey d34021e18f134429107d880a4240f24a
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/client";

export default {
  create: definePathHelper("post", "/api/trees/:tree_id/problems"),
  focus: definePathHelper("patch", "/api/problems/:id/focus"),
  reassign: definePathHelper("patch", "/api/problems/:id/reassign"),
  update: definePathHelper("patch", "/api/problems/:id"),
  destroy: definePathHelper("delete", "/api/problems/:id"),
};
