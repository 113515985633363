import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Heading,
  Link,
  Stack,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";

import theme from "~/application/theme";

const { VITE_PUBLIC_SENTRY_DSN, MODE } = import.meta.env;

Sentry.init({
  dsn: VITE_PUBLIC_SENTRY_DSN,
  environment: MODE,
});

const ErrorComponent = () => (
  <ChakraProvider theme={theme}>
    <Box h="full" w="full">
      <Center h="80vh">
        <Center>
          <Stack spacing={4}>
            <Heading as="h1">Something went wrong...</Heading>
            <Box pt={8}>
              <Button as={Link} href="/" w="full" colorScheme="blue">
                Reload
              </Button>
            </Box>
          </Stack>
        </Center>
      </Center>
    </Box>
  </ChakraProvider>
);

const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={ErrorComponent}>
    {children}
  </Sentry.ErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundary;
