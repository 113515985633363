import { memo } from "react";
import { Icon } from "@chakra-ui/react";

export default memo(function TreeIcon() {
  return (
    <Icon viewBox="0 0 20 20" fill="none" height="20px" w="20px">
      <path
        d="M13.3335 6.66667L13.3335 3.33333C13.3335 2.8731 12.836 2.5 12.2224 2.5L7.77794 2.5C7.16429 2.5 6.66683 2.8731 6.66683 3.33333L6.66683 6.66667C6.66683 7.1269 7.16429 7.5 7.77794 7.5L12.2224 7.5C12.836 7.5 13.3335 7.1269 13.3335 6.66667Z"
        stroke="#77716D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6665 17.1875L16.6665 15.3125C16.6665 15.1399 16.48 15 16.2498 15L13.7498 15C13.5197 15 13.3332 15.1399 13.3332 15.3125L13.3332 17.1875C13.3332 17.3601 13.5197 17.5 13.7498 17.5L16.2498 17.5C16.48 17.5 16.6665 17.3601 16.6665 17.1875Z"
        stroke="#77716D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 17.1875L6.6665 15.3125C6.6665 15.1399 6.47996 15 6.24984 15L3.74984 15C3.51972 15 3.33317 15.1399 3.33317 15.3125L3.33317 17.1875C3.33317 17.3601 3.51972 17.5 3.74984 17.5L6.24984 17.5C6.47996 17.5 6.6665 17.3601 6.6665 17.1875Z"
        stroke="#77716D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.97461 7.5L10.0002 10.8333"
        stroke="#77716D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 14.9999L5 12.8333C5 12.3028 5.22576 11.7941 5.62763 11.419C6.02949 11.044 6.57454 10.8333 7.14286 10.8333L12.8571 10.8333C13.4255 10.8333 13.9705 11.044 14.3724 11.419C14.7742 11.7941 15 12.3028 15 12.8333L15 14.9999"
        stroke="#77716D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
});
